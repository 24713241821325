.program-cards-container {
    padding-top: 60px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
}

.program-cards-container.single {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
}

.program-card {
    border: 1px solid #dedcdc;
    border-radius: 16px;
    flex-direction: column;
    justify-content: center;
    max-height: 100%;
    padding: 40px;
    display: flex;
    text-align: center;
    width: 275px;
    box-shadow: 34px 34px 140px -48px rgba(57, 59, 106, .08);
}

.program-card.big-shadow {
    box-shadow: 0 2px 80px rgba(0, 0, 0, .2);
}


.program-card .heading {
    color: #1D315F;
    font-size: 24px;
    line-height: 1.2;
    letter-spacing: -.02em;
    font-weight: 800;
    /* white-space: pre-wrap; */
}

.program-card .separator {
    background-color: #e4e6f1;
    height: 1px;
    margin-top: 24px;
    margin-bottom: 24px;
}

.program-card *:not(.heading) {
    font-family: Roboto, sans-serif;
}

.program-card .description-content,
.check {
    color: #454545;
    letter-spacing: -.01em;
    font-size: 14px;
    line-height: 1.8;
}

.check:last-of-type {
    margin-bottom: 18px;
}

.checks-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.check {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
}

.choice-button {
    font-weight: 700;
    color: white;
    background-color: #1D315F;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 32px;
    font-size: 14pt;
    letter-spacing: -.02em;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    margin-top: auto;
    transition: background-color .3s;
    text-decoration: none;
}

.choice-button:hover {
    background-color: #282556;
}

.choice-button.outline {
    background-color: #f6f6f6;
    color: #1D315F;
    border: 1px solid #1D315F;
}

.choice-button.outline:hover {
    background-color: #fff;
}

.choice-button.orange {
    background-color: #FF6200;
    color: #fff;
    /* border: 1px solid #1D315F; */
}

.choice-button.outline:hover {
    /* background-color: #fff; */
}

@media (max-width: 990px) {
    .program-cards-container {
        grid-template-columns: 1fr;
        width: calc(100% - 186px);
        padding-inline: 93px;
    }

    .program-card {
        width: calc(100% - 80px);
    }
}

@media (max-width: 480px) {
    .program-cards-container {
        padding: 20px;
        width: 100%;
        box-sizing: border-box;
    }
}