.reel {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0px;
    text-align: center;
    padding-bottom: 40px;
    user-select: none;
}

/* .reel *:is(audio::-webkit-media-controls-current-time-display,
    audio::-webkit-media-controls-timeline,
    audio::-webkit-media-controls-timeline-container,
    audio::-webkit-media-controls-time-remaining-display,
    audio::-webkit-media-controls-timeline) {
    display: none;
}

.reel *:is(audio::-webkit-media-controls-panel) {
    -webkit-justify-content: center;
} */

.reel audio {
    display: none;
}


.reel-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.reel .name {
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    color: black;
}

.reel .subtitle {
    color: #0073DA;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    max-width: 300px;
}

.reel .quote {
    color: #9DAFBD;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-style: italic;
    line-height: 22px;
    width: 200px;
}

.reel .quote::before {
    content: "“";
}

.reel .quote::after {
    content: "”";
}

.reel .text-transcript-link {
    font-family: Roboto, sans-serif;
    color: black;
}

.reel-circle {
    position: relative;
    padding: 27px;
    background-image: url('/public/reel-circle-borders.png');
    background-position: top center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 10px;
}

.reel-button {
    background-color: #FF6200;
    width: 43px;
    height: 43px;
    border-radius: 50%;
    border: none;
    position: absolute;
    bottom: calc(-18px + 27px);
    left: 50%;
    translate: -50% 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    box-shadow: 0px 0px 40px 0px #00000040;
}

.reel-button>img {
    width: 10px;
    height: 10px;
    object-fit: contain;
    object-position: center;
}

.reel-portrait {
    border-radius: 50%;
    object-fit: cover;
    object-position: top center;
    width: 194px;
    height: 194px;
}