#emergency-banner {
    padding: 16px 5%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    color: white;
    font-family: Roboto, sans-serif;
    background-color: #1D315F;
    color: white;
    font-weight: 500;

    font-size: 14px;
    line-height: 1.8;
    letter-spacing: -.02em;
    box-sizing: border-box;
}

#emergency-banner>a {
    background-color: #CA4F02;
    border-radius: 5px;
    font-family: Roboto, sans-serif;
    font: inherit;
    color: white;
    text-decoration: none;
    font-weight: 600;
    padding: 9px 15px;
    box-sizing: border-box;
}

.card-page {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0px;
}

.card-page main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0px;
}

.card-page .heading {
    color: #1D315F;
    letter-spacing: -.02em;
    font-size: 48px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 10px;
    margin-top: 60px;
}

.card-page .description {
    max-width: 885px;
    width: 82%;
    line-height: 32px;
}

.card-page .description,
.card-page .description>strong {
    color: #1D315F;
    font-family: Roboto, sans-serif;
    font-size: 14px;
}

.card-page-card {
    max-width: 1140px;
    box-sizing: border-box;
    box-shadow: 0 2px 80px rgba(0, 0, 0, .2);
    border-radius: 12px;
    padding: 40px 32px;
    /* display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start; */
    display: grid;
    margin-top: 25px;
    align-items: start;
    box-sizing: border-box;
}

#Crisis-Support-page .card-page-card {
    grid-template-columns: 1fr 1px 1fr 1px 1fr;
    gap: 70px;
}

.card-page-card img {
    width: 135px;
    object-fit: contain;
    object-position: center;
}

.card-page-card .heading {
    letter-spacing: -.02em;
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
    color: #1D315F;
}

.card-page-card .description,
.card-page-card .description>* {
    font-family: Roboto, sans-serif;
}

.card-page-card .description {
    letter-spacing: -.01em;
    font-size: 14px;
    line-height: 1.6;
    margin-top: 5px;
}

.card-info {
    max-width: 268.5;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card-page-card .separator {
    width: 1px;
    height: 200px;
    background-color: #ddd;
}

@media (max-width: 990px) {
    .card-page-card {
        width: 100%;
        grid-template-columns: 1fr !important;
        /* grid-row: 1fr; */
        align-items: center;
        gap: 32px !important;
    }

    .card-page-card .separator {
        height: 1px;
        width: 100%;
    }

    /* #Crisis-Support-page .card-page-card {
        gap: 32px;
    } */
}

@media (max-width: 770px) {
    .card-page-card {
        width: calc(100% - 20px);
    }

    .card-page-card>.heading {
        font-size: 32px;
    }

    .card-page .heading {
        font-size: 32px;
    }
}

@media (max-width: 480px) {
    .card-page .description {
        line-height: 20px;
    }
}