#Building-Trust-page,
#Building-Trust-page>main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0px;
}

.heading-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 630px;
    gap: 20px;
    text-align: center;
    width: 100%;
    padding-inline: 30px;
    box-sizing: border-box;
}

.heading-section>.subtitle {
    color: #FF6200;
    margin-top: 50px;
    font-size: 14pt;
    line-height: 24px;
    font-weight: 600;
}

.heading-section>.title {
    color: #1D315F;
    letter-spacing: -.04em;
    font-size: 52px;
    line-height: 1.2;
}

.heading-section>.description {
    color: #1D315F;
    letter-spacing: -.02em;
    font-family: Roboto, sans-serif;
    line-height: 32px;
    font-weight: 500;
}

.feature-card-container {
    padding: 40px 80px;
    display: grid;
    grid-template-columns: 1fr;
    /* grid-template-rows: auto; */
    gap: 24px;
    max-width: 1280px;
}

.feature-card-row {
    display: grid;
    gap: 24px;
}

.feature-card-row:nth-child(1) {
    grid-template-columns: 1.5fr 1fr 1.25fr;
}

.feature-card-row:nth-child(2) {
    grid-template-columns: 1fr 1.25fr 1.5fr;
}



@media (max-width: 990px) {

    .heading-section>.title {
        font-size: 28px;
    }

    .heading-section>.subtitle {
        font-size: 16px;
    }

    .heading-section>.description {
        font-size: 14px;
    }

    .feature-card-container .feature-card-row {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 770px) {

    .heading-section>.title {
        font-size: 24px;
    }

    .heading-section>.subtitle {
        font-size: 14px;
    }

    .feature-card-container .feature-card-row {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 480px) {
    .feature-card-container {
        padding: 20px;
    }
}