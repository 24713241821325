.carousel-dot {
    background-color: white;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    cursor: pointer;
    border: 3px solid #aaa;
}

.carousel-dot.active {
    background-color: black;
}