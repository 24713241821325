#footer {
  gap: 30px;
}

.footer-copyright,
.footer-copyright * {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 26px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  text-align: center;
}

.footer-images {
  /* height: 50px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  /* gap: 140px; */
  justify-content: space-around;
  width: 100%;
  max-width: 200px;
}

.footer-images .org-logo {
  max-width: 100%;
}

#footer .links {
  display: flex;
  flex-direction: row;
  gap: 4px;
  text-align: center;
}

.links *:is(a, a:visited) {
  color: #CA4F02;
}

/* @media (max-width: 1100px) { */
#footer.stack .footer-copyright {
  flex-direction: column;
  align-items: center;
}

/* } */

@media (max-width: 910px) {
  .footer-copyright {
    flex-direction: column;
    align-items: center;
  }
}