#Readiness-Checks-page>*:is(.heading, .description),
#Readiness-Checks-page>main>*:is(.heading, .description) {
    max-width: 410px;
}

#Readiness-Checks-page>.card-page-card,
#Readiness-Checks-page>main>.card-page-card {
    grid-template-columns: 1fr 1px 1fr 1px 1fr 1px 1fr;
    gap: 40px;
}

#Readiness-Checks-page .CTA-button {
    margin-top: 40px;
}