.card-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    /* justify-content: center; */
    justify-items: center;
    width: 100%;
    max-width: 2200px;
}

.card-grid .card {
    border: 1px solid #EEEEEE;
    border-radius: 14px;
    padding: 40px 40px 20px 40px;
    display: flex;
    flex-direction: row;
    gap: 37px;
    max-width: 790px;
}

.card-grid .card:hover {
    background-color: #f7f9fa;
}


.card-grid .card-img {
    width: 81px;
    height: 81px;
}

.card-grid .card-content {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 28px;
    gap: 9px;
}

.card-grid .card .title {
    font-size: 18px;
    font-weight: 600;
    color: #1D3160;
}

.card-grid .card .description {
    font-family: Roboto, sans-serif;
    /* max-width: 597px; */
    width: fit-content;
}

@media (max-width: 570px) {
    .card-grid .card {
        padding: 24px;
        gap: 10px;
        flex-direction: column;
        align-items: center;
    }

    .card-grid .card .card-img {
        /* width: 50px;
        height: 50px; */
    }

    .card-grid .card .title {
        font-size: 18px;
    }

    .card-grid .card .description {
        font-size: 14px;
        line-height: 24px;
    }

}