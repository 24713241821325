#Email-Suggestions-page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0px;
}

.email-suggestions-body {
    /* display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 72px;
    padding: 60px;
    max-width: 1026px;
}

.email-suggestions-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 36px;
    color: #1D315F;
}

.email-suggestions-text .heading {
    letter-spacing: -.02em;
    font-size: 48px;
    line-height: 1.2;
    margin-bottom: 24px;
}

.email-suggestions-text .description {
    letter-spacing: -.02em;
    font-size: 16px;
    line-height: 1.8;
    font-family: Roboto, sans-serif;
}

.email-cards-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
}

@media (max-width: 990px) {
    .email-suggestions-body {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 770px) {
    .email-cards-container {
        grid-template-columns: 1fr;
    }

    .email-suggestions-text .heading {
        font-size: 40px;
    }
}

@media (max-width: 480px) {
    .email-suggestions-text .heading {
        font-size: 30px;
    }

    .email-suggestions-body {
        padding: 20px;
    }

}