.carousel-container,
.carousel-wrapper {
    /* width: calc(100% + 00px); */
    width: 100%;
    height: 100%;
    position: relative;
    /* padding: 0 150px; */
    /* left: -150px; */
}


.carousel-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0px;
}

/* .react-multi-carousel-dot-list  {
    top: 0;
} */

/* .react-multi-carousel-item {
} */

.carousel-dot:only-child {
    visibility: hidden;
}

.react-multi-carousel-dot-list>li {
    position: relative;
    left: 3px;
}

.react-multi-carousel-dot-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.carousel-arrow {
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    background-color: #909090;
    border: none;
    border-radius: 50%;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
}

.carousel-arrow:disabled {
    visibility: hidden;
}