.CTA-button {
    border: none;
    background-color: #FF6200;
    /* padding: 18px 46px; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14pt;
    color: white;
    width: 300px;
    max-width: 100%;
    min-width: min-content;
    height: 40px;
    min-height: 58px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
}

.CTA-button.alt {
    background-color: #0094FF;
}

.CTA-button.alt.outline {
    background-color: transparent;
    border: 1px solid #0094FF;
    color: #0094FF;
}