:root {
  scroll-behavior: smooth;
}

* {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
}

#home-page {
  display: flex;
  flex-direction: column;
}

section {
  padding: 80px 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

#hero-body {
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-block: 50px 40px;
  gap: 40px;
}

#hero-section {
  min-height: 100vh;
  box-sizing: border-box;
  justify-content: space-between;
  height: max-content;
  background-color: #1D3160;

}

.org-logo {
  object-fit: contain;
  object-position: center;
}

#hero-section .org-logo {
  margin-bottom: 20px;
  object-fit: contain;
  overflow: visible;
}

/* #hero-section .org-logo.bigger-logo {
  width: 150px;
  height: 120px;
} */

/* #hero-section .org-logo.challenge-coin {
  width: 150px;
  height: 150px;
} */


.police-hero-logo {
  margin-bottom: 40px;
  width: 75px;
  object-fit: contain;
  object-position: center;
}

#hero-body-content {
  font-size: 54px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -0.01em;
  max-width: 800px;
}

#hero-body-content>span {
  font-weight: 300;
  font-style: italic;
  letter-spacing: inherit;
}

#hero-body>p {
  max-width: 466px;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  line-height: 26px;
}

.bottom-of-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  /* margin-top: 136px; */
  margin-bottom: -80px;
}

.down-arrows {
  cursor: pointer;
  width: 40px;
  height: 60px;
}

.bottom-vertical-line {
  width: 1px;
  height: 78px;
  background-color: #D1DBE3;
}

.reels-container-heading {
  color: #0073DA;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-block: 25px;
  text-align: center;
}

.wellness-hub-links {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  /* font-weight: 600; */
  line-height: 26px;
  /* display: flex;
  flex-direction: row; */
  /* gap: 12px; */
  margin-block: 26px 40px;
  text-align: left;
}

.wellness-hub-links strong {
  font-family: Roboto, sans-serif;
  font-weight: 600;
}


.wellness-hub-links a:is(*) {
  color: #1D3160;
  text-decoration: none;
  width: max-content;
}

#keel-reels-section {
  margin-bottom: 80px;
  margin-inline: auto;
  max-width: 1500px;
  align-self: center;
  box-sizing: border-box;
  width: 100%;
}

#wellness-hub-section {
  /* height: 340px; */
  padding-block: 65px;
  background-color: #E7EBF3;
}

.grid-container {
  display: flex;
  gap: 90px;
  flex-wrap: wrap;
  align-items: center;
  /* height: 340px; */
  width: 100%;
  justify-content: center;
}

.grid-content {
  max-width: 100%;
}

.grid-content .description {
  max-width: 600px;
}

/* .wellness-hub-graphic-container {
  height: 350px;
} */

.graphic-container {
  width: 665px;
  /* height: 350px; */
}

*:is(.graphic-container)>img {
  object-fit: contain;
  object-position: center;
  width: 665px;
  height: 100%;
}


#keel-games-graphic-container>img {
  width: 489px;
}

.games-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 22px;
  margin-top: 33px;
}

#bottom-hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

#bottom-hero>.heading {
  font-size: 40px;
  font-weight: 600;
  line-height: 46px;
  text-align: center;
  color: white;
  max-width: 1222px;
}

#bottom-hero>.description {
  font-family: Roboto, sans-serif;
  color: white;
  margin-bottom: 30px;
  text-align: center;
}

#bottom-hero>.heading>span {
  font-style: italic;
  font-weight: 300;
  color: #0094FF;
}

.default-org-page .org-element:not(.default-org-element) {
  display: none !important;
}


@media (max-width: 800px) {
  section {
    padding: 40px 75px;
  }

  .graphic-container {
    max-width: 100%;
  }

  .graphic-container>img {
    width: 100% !important;
  }

  .bottom-of-section {
    margin-bottom: -40px;
  }
}

@media (max-width: 530px) {
  section {
    padding: 40px 20px;
  }

  #building-trust-section {
    padding-block: 75px;
  }

  #bottom-hero .heading {
    font-size: 26px;
    line-height: 30px;
  }
}

@media (max-width: 840px) {
  #hero-body-content {
    font-size: max(6.4vw, 28px);
    line-height: max(7.1vw, 28px);
  }
}