.section-header {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.section-header.compact {
    text-align: start;
    align-items: flex-start;
}

.section-header>.subtitle {
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    color: #FF6200;
}

.section-header>.title {
    font-size: 30px;
    font-weight: 700;
    line-height: 44px;
    color: #1D3160;
    white-space: pre-wrap;
}

.section-header.dark>*:is(.title, .description) {
    color: white;
}

.section-header.compact>.separator {
    background-color: #FF6200;
}

.section-header.dark>.separator {
    background-color: #0094FF;
}

.section-header.dark>.subtitle {
    color: #0094FF;
}


.section-header>.separator {
    margin-block: 5px;
    height: 3px;
    width: 60px;
    background-color: #1D3160;
    /* display: none; */
}

.section-header>.separator.long {
    width: 120px;
}

.section-header>.description {
    font-family: Roboto, sans-serif;
    line-height: 20px;
    font-size: 14px;
    color: #555;
}

.section-header.compact>.description {
    line-height: 26px;
}

.section-header img {
    max-width: 200px;
    width: 100%;
}