.email-card {
    border: 1px solid #eee;
    border-radius: 24px;
    padding: 24px;
    text-decoration: none;
    box-sizing: border-box;
    font-family: Roboto, sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #fff;
    gap: 15px;
}

.email-card:hover {
    background-color: #f7f9fa;
}

.email-card * {
    font-family: inherit;
}

.email-card .title {
    font-weight: 600;
    color: #1D315F;
    margin-top: 15px;
}

.email-card .description {
    color: #333;
    letter-spacing: -.02em;
    font-size: 14px;
    line-height: 1.8;
    font-weight: 500;
}

.email-card img {
    height: 24px;
    object-fit: contain;
    object-position: center;
}

.email-card .img-container {
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, .05);
    width: 48px;
    height: 48px;
    box-sizing: border-box;
    border-radius: 50%;
}