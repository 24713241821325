.hamburger-button {
    width: 60px;
    height: 60px;
    background-color: rgba(0, 0, 0, 0.2);
    border: none;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 16px;
    cursor: pointer;
}

.hamburger-button:hover {
    background-color: rgba(0, 0, 0, 0.4);
}

.hamburger-button .line {
    width: 100%;
    height: 3px;
    border-radius: 20px;
    background-color: white;
}

.hamburger-menu-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    /* overflow: scroll; */
    /* min-height: fit-content; */

}

.hamburger-menu-wrapper .cover {
    background: #000;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity ease 0.5s;
}

.menu-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 40px;
    gap: 10px;
}

.menu-header .header-logo-a {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px;
}

.menu-scroll-wrapper {
    position: relative;
    width: 380px;
    padding-top: 37px;
    padding-left: 80px;
    height: 100%;
    max-height: 100vh;
    overflow: scroll;
    background-color: #0C254E;

    transition: width ease 0.5s, padding-left ease 0.5s;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}


.menu-scroll-wrapper::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.menu-scroll-wrapper {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    min-height: fit-content;
    padding-bottom: 100px;
    width: 380px;

}

#home-page .menu {
    /* padding-top: 89.5px; */
}

.menu a:is(*) {
    /* margin-left: 40px; */
    color: white;
    text-decoration: none;
    font-weight: 600;
    font-size: 24px;
    width: max-content;
    opacity: 1;
}

.menu a,
.close-button {
    transition: opacity ease 0.25s;
}

.hamburger-menu-wrapper:not(.show) {
    pointer-events: none;
}

.hamburger-menu-wrapper:not(.show) .cover {
    opacity: 0;
}

.hamburger-menu-wrapper:not(.show) .menu-scroll-wrapper {
    width: 0;
    padding-left: 0;
}

.hamburger-menu-wrapper:not(.show) *:is(.menu a, .close-button) {
    opacity: 0;
}

.close-button {
    position: relative;
    width: 60px;
    min-width: 60px;
    height: 60px;
    background-color: rgba(0, 0, 0, 0.2);
    border: none;
    border-radius: 8px;
    /* display: flex; */
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px;
    cursor: pointer;
    opacity: 1;
    /* align-self: flex-end; */
    margin-right: 40px;
}

.close-button:hover {
    background-color: rgba(0, 0, 0, 0.4);
}

.close-button .line {
    position: absolute;
    width: calc(100% - 32px);
    height: 3px;
    border-radius: 20px;
    background-color: white;
}

.close-button .line:nth-child(1) {
    rotate: 45deg;
}

.close-button .line:nth-child(2) {
    rotate: -45deg;
}

.hamburger-button.dark {
    background-color: #00000000;
    border: 1px solid #EEEEEE;
}

.hamburger-button.dark:hover {
    background-color: rgba(0, 0, 0, 0.1);
}


.hamburger-button.dark .line {
    background-color: #0C254E;
}


@media (max-width: 800px) {
    .menu-scroll-wrapper {
        width: 100%;
        padding-left: 0;
    }

    .menu {
        align-items: center;
        gap: 6vw;
    }

    .menu a {
        font-size: clamp(14px, 7.3vw, 44px);
        /* 600px */
    }

    .menu-header .header-logo {
        width: 50%;
        min-width: 110px;
    }

    .menu-header .header-logo-a {
        flex-grow: 1;
    }

    .menu-header {
        margin-bottom: 5vw;
        justify-content: center;
        width: 100vw;

    }

    .close-button {
        display: flex;
    }

    .close-button.hidden {
        pointer-events: none;
        visibility: hidden;
        opacity: 0;
        display: flex;
    }
}

@media (max-width: 400px) {
    .close-button {
        width: 50px;
        height: 50px;
        min-width: 50px;
        align-self: center;
    }

    .hamburger-button {
        width: 50px;
        height: 50px;
        min-width: 50px;
    }

    .menu-header {
        gap: 20px;
    }
}