#Program-Choice-page>.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0px;
    padding-top: 40px;
}

#wellness-journey {
    letter-spacing: -.04em;
    font-size: 48px;
    font-weight: 700;
    line-height: 1.2;
    max-width: 630px;
    text-align: center;
    color: #1D315F;
}

@media (max-width: 990px) {
    #wellness-journey {
        font-size: 30px;
    }
}

@media (max-width: 500px) {
    #wellness-journey {
        /* max-w */
        padding-inline: 20px;
        font-size: 24px;
        margin-bottom: 40px;
    }
    
}