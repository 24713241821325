.feature-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    border: .5px solid #d5d9e2;
    border-radius: 16px;
    padding: 32px;
    box-shadow: 0 1px 2px rgba(35, 39, 46, .08);
    box-sizing: border-box;
}

.feature-card:hover {
    background-color: #f4f7fb;
}

.feature-card>.tag {
    font-size: 11px;
    border-radius: 5px;
    /* padding-top: 5px;
    padding-bottom: 5px; */
    padding: 5px 10px;
    font-weight: 500;
}

.feature-card>.tag.orange {
    color: #c53636;
    background-color: #faf0e8;
}

.feature-card>.tag.blue {
    color: #163b7b;
    background-color: #d8f4ff;
}

.feature-card>.tag.green {
    color: #0a832b;
    background-color: #deffe0;
}

.feature-card>.heading {
    letter-spacing: -.015em;
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    color: #1D315F;
}

.feature-card>.description {
    color: #525f75;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    line-height: 28px;
    font-weight: 500;
}

.feature-card>.description>a:is(*) {
    color: #CA4F02;
    font: inherit;
}


@media (min-width: 991px) {
    .feature-card-row:nth-child(1) .feature-card {
        min-height: 256px;
    }

    .feature-card-row:nth-child(2) .feature-card {
        min-height: 284px;
    }
}


.feature-card.show-when-2-cols {
    display: none;
}

@media (max-width: 990px) and (min-width: 771px) {
    .feature-card.show-when-2-cols {
        display: flex;
    }

    .feature-card.hide-when-2-cols {
        display: none;
    }
}