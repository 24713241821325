.header-logo {
  object-fit: contain;
  object-position: center;
  width: 217px;
}

.header {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.header-logo-a {
  height: 79px;
}

.basic>.header {
  padding: 24px;
  width: 100%;
  box-sizing: border-box;
}

.ghost {
  opacity: 0;
  visibility: hidden;
  pointer-events: 0;
}

@media (max-width: 800px) {
  .header {
    /* flex-direction: column; */
    /* width: calc(100% - 80px); */
    /* gap: 20px; */
    padding-inline: 40px;
  }

  #home-page .header {
    margin-top: 40px;
  }
}

@media (max-width: 530px) {
  #home-page .header {
    /* flex-direction: column; */
    gap: 20px;
    margin-top: 0;
  }

  .header .header-logo {
    /* width: 180px; */
    width: max(100%, 120px);
    max-height: 70px;
    margin-top: 10px;
  }

  .header .hamburger-button {
    align-self: flex-start;
    margin-top: 9.5px;
  }

  #home-page .header .hamburger-button.ghost {
    /* display: none; */
  }

  #hero-section p {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }
}





@media (min-width: 801px) {
  #home-page .header {
    width: calc(100% + 150px);
  }
}