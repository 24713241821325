#No-Codes-Peer-Support-Program-page {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0px;
    height: 900px;
    min-height: 100vh;
}

#No-Codes-Peer-Support-Program-page .hero {
    width: 45%;
    height: 100%;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    padding: 24px;
    box-sizing: border-box;
}

#No-Codes-Peer-Support-Program-page .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 55%;
    max-width: calc(500px + 6%);
    padding-inline: 3%;
    box-sizing: border-box;
    /* height: 900px; */
    height: 100%;
    padding-top: 115px;
}

#Has-Codes-Peer-Support-Program-page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0px;
}

.peer-support-program-page .content>.heading {
    color: #1D315F;
    letter-spacing: -.02em;
    font-size: 48px;
    line-height: 1.4;
    margin-bottom: 10px;
    margin-top: 60px;
}

#Has-Codes-Peer-Support-Program-page>.content>.heading {
    text-align: center;
}

#No-Codes-Peer-Support-Program-page .content>.description {
    letter-spacing: -.02em;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 1.8;
    margin-bottom: 30px;
}

#No-Codes-Peer-Support-Program-page .content>.description>strong {
    font-family: inherit;
}

#No-Codes-Peer-Support-Program-page .form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    font-family: Roboto, sans-serif;
    margin-bottom: 34px;
}

#No-Codes-Peer-Support-Program-page .form-container form #email-label strong {
    position: absolute;
    top: -100%;
    transform: translateY(100%);
    left: 10px;
    font-family: Roboto, sans-serif;
}

#No-Codes-Peer-Support-Program-page .form-container form {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;
    margin-top: 50px;
    position: relative;
}

#No-Codes-Peer-Support-Program-page .form-container label {
    width: 100%;
}

#No-Codes-Peer-Support-Program-page .form-container label input {
    width: 100%;
}

#No-Codes-Peer-Support-Program-page .form-container input[type="email"]::placeholder {
    color: #B7B9CE;
    font-weight: 500;
}

#No-Codes-Peer-Support-Program-page .form-container input[type="email"] {
    border: 1px solid #eee;
    color: black;
    background-color: #fff;
    border-radius: 8px;
    min-height: 48px;
    padding: 12px 16px;
    box-sizing: border-box;
    flex-grow: 1;
    font-family: Roboto, sans-serif;

    font-size: 14px;
    line-height: 1.42857;
}

#No-Codes-Peer-Support-Program-page .form-container input[type="submit"] {
    border: 1px solid #eee;
    color: #1D315F;
    letter-spacing: -.02em;
    background-color: #fff;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    min-height: 0;
    padding: 12px 32px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 800;
    transition: border-color .3s, background-color .3s;
    display: flex;
    cursor: pointer;
}

#No-Codes-Peer-Support-Program-page .form-container input[type="submit"]:hover {
    background-color: #f3f5fb;
}

#No-Codes-Peer-Support-Program-page .content *:is(.header-logo, .heading) {
    align-self: flex-start;
}

.form-success {
    color: #006638;
    background-color: #ebfff5;
    text-align: center;
    border-radius: 5px;
    /* padding: 10px; */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    /* height: 40px; */
    width: 100%;
    padding-block: 8px;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 14px;
    font-family: Roboto, sans-serif;
}

.form-error {
    color: #b54100;
    background-color: #ffdede;
    text-align: center;
    border-radius: 5px;
    /* padding: 10px; */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    /* height: 40px; */
    width: 100%;
    padding-block: 8px;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 14px;
    font-family: Roboto, sans-serif;
}

.other-email {
    color: #4a6da9;
    background-color: #d8f4ff;
    text-align: center;
    border-radius: 5px;
    /* padding: 10px; */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    /* height: 40px; */
    width: 100%;
    padding-block: 8px;
    box-sizing: border-box;
    font-weight: 500;
}

.other-email>span {
    font-family: Roboto, sans-serif;
    font-size: 14px;
}

.other-email a {
    font: inherit;
    color: inherit;
}

.separator-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 32px;
    width: 100%;
}

.separator-container span {
    font-family: Roboto, sans-serif;
}

#No-Codes-Peer-Support-Program-page .separator {
    height: 1px;
    flex-grow: 1;
    background-color: #eee;
}

.assistance-button {
    background-color: #1D315F;
    color: white;
    text-decoration: none;
    font-family: Roboto, sans-serif;
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    border-radius: 5px;
}

.assistance-button:hover {
    background-color: #8b96ba;
}

@media (max-width: 990px) {
    #No-Codes-Peer-Support-Program-page .hero {
        width: 40%;
    }

    #No-Codes-Peer-Support-Program-page .content {
        width: 60%;
    }
}

@media (max-width: 770px) {
    #No-Codes-Peer-Support-Program-page {
        flex-direction: column;
        height: fit-content;
        align-items: center;
    }

    #No-Codes-Peer-Support-Program-page .content {
        padding-inline: 5%;
        width: 100%;
        align-items: center;
        padding-top: 40px;
    }

    #No-Codes-Peer-Support-Program-page .hero {
        width: 100%;
        /* height: 500px; */
        aspect-ratio: 1.78;
    }

    #No-Codes-Peer-Support-Program-page .content .heading {
        margin-top: 20px;
    }

    #No-Codes-Peer-Support-Program-page .content *:is(.header-logo, .heading) {
        align-self: center;
        text-align: center;
    }
}

@media (max-width: 480px) {
    #No-Codes-Peer-Support-Program-page .content>.heading {
        font-size: 34px;
    }

    #No-Codes-Peer-Support-Program-page .content form {
        flex-direction: column;
    }

    #No-Codes-Peer-Support-Program-page .form-container form #email-label strong {
        left: 0;
        top: -30px;
        transform: none;
    }
}